import moment from "moment";
import {mapActions} from "vuex";

export default {
    data() {
        return {
            interactionToEdit: null,
            interactionToAddNote: null,
            templateLoading: false,
            modal: null,
            tableUpdated: moment().unix(),
            addNoteLoading: null,
            deleteLoading: null,
            interactionToDelete: null,
            interactionToCancel: null,
            editLoading: null,
            cancelLoading: null,
            company: null,
            noteToEdit: null,
        }
    },
    methods: {
        ...mapActions({
            setPreventErrorHandling: "app/setPreventErrorHandling",
            setCurrentError: 'app/setCurrentError',
        }),
        async addInteraction(company) {
            if (!this.interactionTemplate || this.company !== company) {
                this.company = company;
                this.templateLoading = true;
                await this.loadInteractionTemplate(company);
                this.templateLoading = false;
            }

            this.interactionToEdit = this.interactionTemplate;
            this.$nextTick(() => {
                this.openEditModal();
            })
        },
        openShowNoteModal() {
            if (this.modal) {
                console.error("There is a modal?!");
                return;
            }

            this.modal = this.openModal(this.$refs.showNoteModal, {
                backdrop: "static",
                keyboard: false,
            });
        },
        openAddNoteModal() {
            // console.warn("noteToEdit", this.noteToEdit);
            // console.warn("interactionToAddNote", this.interactionToAddNote);
            // console.warn(this.noteToEdit && this.interactionToAddNote.id > 0);

            // console.warn("refs", this.$refs);
            // console.warn("modal", this.$refs.addNoteToInteractionModal);

            if (this.modal) {
                console.error("There is a modal?!");
                return;
            }

            this.modal = this.openModal(this.$refs.addNoteToInteractionModal, {
                backdrop: "static",
                keyboard: false,
            });
        },
        addNoteToInteraction(interactionId) {
            this.addNoteLoading = interactionId;

            // Load the full interaction
            const a = this.$axios.get(`/api/interactions/${interactionId}`)
                .then((response) => {
                    this.interactionToAddNote = response.data;
                });

            // Load the note template
            const b = this.$axios.get(`/api/notes/empty?interactionId=${interactionId}`)
                .then((response) => {
                    this.noteToEdit = response.data;
                });

            Promise.all([a, b]).then(() => {
                this.$nextTick(this.openAddNoteModal);
            }).finally(() => {
                this.addNoteLoading = null;
            });
        },
        editNote() {
            this.closeModal(this.modal);
            this.modal = null;

            this.$nextTick(this.openAddNoteModal);
        },
        editNoteOfInteraction(ids) {
            this.addNoteLoading = ids.interactionId;

            // Load the full interaction
            const a = this.$axios.get('/api/interactions/' + ids.interactionId)
                .then((response) => {
                    this.interactionToAddNote = response.data;
                });

            // Load the note
            const b = this.$axios.get("/api/notes/" + ids.noteId)
                .then((response) => {
                    this.noteToEdit = response.data;
                });

            Promise.all([a, b]).then(() => {
                this.$nextTick(this.openShowNoteModal);
            }).finally(() => {
                this.addNoteLoading = null;
            });
        },
        notesUpdated() {
            this.tableUpdated = (this.tableUpdated ? this.tableUpdated : moment().unix()) + 1; // Increment timestamp to trigger reload (must be timestamp!)
            this.closeModal(this.modal);
            this.modal = null;
            this.$emit('interaction-notes-updated');
        },
        interactionsUpdated() {
            console.log("Calling interactionsUpdated...");
            // Increment timestamp to trigger reload (must be timestamp!)
            this.tableUpdated = (this.tableUpdated ? this.tableUpdated : moment().unix()) + 1;
            this.closeModal(this.modal);
            this.modal = null;
        },
        closeNodeModal(args) {
            if (args.reload) {
                // Increment timestamp to trigger reload (must be timestamp!)
                this.tableUpdated = (this.tableUpdated ? this.tableUpdated : moment().unix()) + 1;
            }
            this.closeModal(this.modal);
            this.modal = null;
        },
        editInteraction(id) {
            this.editLoading = id;
            this.setPreventErrorHandling(true);
            this.$axios
                .get('/api/interactions/' + id)
                .catch((err) => {
                    if (err.response && err.response.status && err.response.status === 404) {
                        // Interaction does not exist anymore -> tell user
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "danger",
                            title: "Interaction cannot be edited",
                            message: "Interaction does not exit anymore",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                            // Increment timestamp to trigger reload (must be timestamp!)
                            this.tableUpdated = (this.tableUpdated ? this.tableUpdated : moment().unix()) + 1;
                        })
                    } else {
                        this.setCurrentError(err);
                    }
                })
                .then((response) => {
                    this.interactionToEdit = response.data;
                    this.$nextTick(() => {
                        this.openEditModal();
                    })
                })
                .finally(() => {
                    this.setPreventErrorHandling(false);
                    this.editLoading = null;
                });
        },
        deleteInteraction(id) {
            this.interactionToDelete = id;
            this.openModal(this.$refs.confirmDeleteModal);
        },
        cancelInteraction(id) {
            this.interactionToCancel = id;
            this.openModal(this.$refs.confirmCancellationModal);
        },
        doDeleteInteraction() {
            this.deleteLoading = this.interactionToDelete;
            this.$axios.delete('/api/interactions/' + this.interactionToDelete)
                .then((response) => {
                    this.tableUpdated = (this.tableUpdated ? this.tableUpdated : moment().unix()) + 1; // Increment timestamp to trigger reload (must be timestamp!)
                }).finally(() => {
                this.interactionToDelete = null
                this.deleteLoading = null;
            });
        },
        doCancelInteraction() {
            this.cancelLoading = this.interactionToCancel;
            this.$axios.post(`/api/interactions/${this.interactionToCancel}/cancel`)
                .then((response) => {
                    this.tableUpdated = moment().unix();
                })
                .finally(() => {
                    this.interactionToCancel = null;
                    this.cancelLoading = null;
                });
        },
        /* changeVisibility(id) {
            this.$axios.post('/api/interactions/change_visibility/' + id)
                .then((response) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "updated",
                        message: "Visibility updated",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                    this.tableUpdated =  (this.tableUpdated ? this.tableUpdated : moment().unix()) + 1; // Increment timestamp to trigger reload (must be timestamp!)
                });
        },  */
        cancelDeleteInteraction() {
            this.interactionToDelete = null;
        },
        openEditModal() {
            this.modal = this.openModal(this.$refs.editInteractionsModal);
        },
        onEditModalClose() {
            this.interactionToEdit = null;
            this.modal = null;
        },
        loadInteractionTemplate(company = null) {
            let companyId = null;
            if (company) {
                if (company.id > 0) {
                    companyId = company.id;
                } else if (company['@id']) {
                    companyId = parseInt(company['@id'].replace(/[^\d.]/g, ''));
                } else {
                    console.error("cannot find company id", company);
                }
            }
            let url = null;
            if (companyId > 0) {
                url = "/api/interactions/empty?" + this.buildQueryString({forCompanyId: companyId});
            } else {
                url = "/api/interactions/empty";
            }
            return this.$axios.get(url)
                .then((response) => {
                    this.interactionTemplate = response.data;
                });
        },
        openInteractionDownloadModal() {
            this.modal = this.openModal(this.$refs.interactionDownloadModal);
        },
        closeInteractionDownloadModal() {
            this.closeModal(this.modal);
            this.modal = null;
        },
    }
}
