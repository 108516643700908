const TYPE_EMAIL_INQUIRY = "Email Inquiry";
const TYPE_IN_PERSON_MEETING = "In-Person Meeting";
const TYPE_INBOUND_PHONE_ZOOM_CALL = "Inbound Phone/Zoom Call";
const TYPE_MODEL_REQUEST = "Model Request";
const TYPE_OUTBOUND_EMAIL = "Outbound Email";
const TYPE_OUTBOUND_CALL = "Outbound Call";
const TYPE_OTHER = "Other";
export const TYPES = [TYPE_EMAIL_INQUIRY, TYPE_IN_PERSON_MEETING, TYPE_INBOUND_PHONE_ZOOM_CALL, TYPE_MODEL_REQUEST, TYPE_OUTBOUND_CALL, TYPE_OUTBOUND_EMAIL, TYPE_OTHER];

const SOURCE_CORPORATE_ACCESS = "Corporate Access";
const SOURCE_DIRECT_COMM = "Direct Comm.";
const SOURCE_SALES_JB = "Sales - JB";
const SOURCE_SALES_NORM = "Sales - Norm";
const SOURCE_OTHER = "Other";
const SOURCE_SALES = "Sales";
const SOURCE_SALES_NORM_JB = "Sales - Norm, JB";
const SOURCE_SALES_JB_NORM = "Sales - JB, Norm";
export const SOURCES = [SOURCE_CORPORATE_ACCESS, SOURCE_DIRECT_COMM, SOURCE_SALES, SOURCE_SALES_JB, SOURCE_SALES_NORM, SOURCE_SALES_NORM_JB, SOURCE_SALES_JB_NORM, SOURCE_OTHER];


export const INVESTOR_LOG = 0;
export const MANAGEMENT_LOG = 1;
export const LOG_TYPES = [INVESTOR_LOG, MANAGEMENT_LOG];

export default {
    methods: {
        doDeleteCallLog(id) {
            this.$axios.delete(`/api/call_logs/${id}`)
                .then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Call Log deleted",
                        message: "The call log has been deleted successfully.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                })
                .finally(() => {
                    this.modal = null;
                    this.tableUpdate = new Date();
                });
        },
    }
}

