export default {
    methods: {
        loadMimeType(file) {
            return new Promise((resolve) => {
                if (window.FileReader && window.Blob) {
                    const fileReader = new FileReader();
                    fileReader.onloadend = function (e) {
                        const arr = (new Uint8Array(e.target.result)).subarray(0, 4);
                        let header = '';
                        for (let i = 0; i < arr.length; i++) {
                            header += arr[i].toString(16);
                        }

                        // Check the file signature against known types
                        switch (header) {

                            case '00018':
                            case '00020':
                                resolve('video/mp4');
                                break;
                            case '89504e47':
                                resolve('image/png');
                                break;
                            case '47494638':
                                resolve('image/gif');
                                break;
                            case "ffd8ffe0":
                            case "ffd8ffe1":
                            case "ffd8ffe2":
                            case "ffd8ffe3":
                            case "ffd8ffe8":
                                resolve('image/jpeg');
                                break;
                            case '25504446':
                                resolve('application/pdf');
                                break;
                            case '73747269':
                            case '504b0304':
                            case '504b34':
                                resolve('application/docx');
                                break;

                            case '7b5c7274':
                            case 'd0cf11e0':
                            case 'd0cf11e0a1b11ae1':
                                resolve('application/doc');
                                break;

                            case '4e6f7465':
                                resolve('text/plain')
                                break;
                            case '3c646976':
                            case '3c6d6169':
                            case '3c6e6176':
                                resolve('text/html')
                                break;
                            default:

                                if(file.type === "video/mp4"){
                                    resolve('video/mp4');
                                    break;
                                }

                                console.error("unsupported: ", header);

                                // eslint-disable-next-line no-case-declarations
                                const ext =  file.name.split('.').pop();

                                if(ext === "txt" || !ext){
                                    resolve("text/plain");
                                    break;
                                }else if(["htm","html"].includes(ext)){
                                    resolve("text/html");
                                    break;
                                }

                                resolve("no-supported-mime-type");
                                break;
                        }

                    };
                    fileReader.readAsArrayBuffer(file);

                } else {
                    // no browser support, fallback to blob mime type
                    resolve(file.type);
                }

            });
        }
    }
}
