import vueInViewportMixin from "vue-in-viewport-mixin/index";


export default {
    mixins: [vueInViewportMixin],
    data() {
        return {
            loadDataTrigger: false,
        }
    },
    watch: {
        'inViewport.now'(visible) {
            if (visible) {
                this.loadDataTrigger = true;
            }
        },
    },
}
