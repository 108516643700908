export default {
    data(){
        return {
            popoverOpen: false
        }
    },
    methods:{
        onPopoverOpened() {
            this.popoverOpen = true;
        },
        onPopoverClosed() {
            this.popoverOpen = false;
        },
    }
}
