export default {
    data() {
        return {
            // Color values from bootstrap CSS
            successColor: {
                r: 40,
                g: 154,
                b: 21,
            },
            warningColor: {
                r: 255,
                g: 210,
                b: 125,
            },
            dangerColor: {
                r: 173,
                g: 47,
                b: 62,
            },
            mutedColor: {
                r: 173,
                g: 181,
                b: 189,
            },
        }
    },
    methods: {
        getColorForGoalNumbers(currentNumber, targetNumber, eventIsCompleted = false) {
            if (eventIsCompleted && currentNumber < targetNumber) {
                return this.rgbToHex(this.mutedColor);
            }

            if (currentNumber >= targetNumber) {
                return this.rgbToHex(this.successColor);
            }

            const finalColor = this.lerpColor(this.dangerColor, this.warningColor, currentNumber / targetNumber);

            return this.rgbToHex(finalColor);
        },
        /**
         * Linearly interpolates between colors a and b by t.
         * t is clamped between 0 and 1. When t is 0, returns a. When t is 1, returns b.
         */
        lerpColor(a, b, t) {
            const clampedT = this.clamp(t, 0, 1);
            return {
                r: Math.floor(this.lerp(a.r, b.r, clampedT)),
                g: Math.floor(this.lerp(a.g, b.g, clampedT)),
                b: Math.floor(this.lerp(a.b, b.b, clampedT),)
            }
        },
        /**
         * Linearly interpolates between two values x and y by t.
         * https://en.wikipedia.org/wiki/Linear_interpolation
         */
        lerp(x, y, t) {
            return x * (1 - t) + y * t;
        },
        clamp(num, min, max) {
            return Math.min(Math.max(num, min), max);
        },
        /**
         * https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
         */
        rgbToHex(c) {
            return "#" + (1 << 24 | c.r << 16 | c.g << 8 | c.b).toString(16).slice(1);
        },
    },
}
