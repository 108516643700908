export const SENT = 'SENT';
export const NOT_SENT = 'NOT SENT';
export const NOT_NEEDED = 'NOT NEEDED';
export const FEEDBACK_STATUS = [
    {
        label: "Sent",
        value: SENT,
    },
    {
        label: "Not Sent",
        value: NOT_SENT,
    },
    {
        label: "Not Needed",
        value: NOT_NEEDED
    }
];
