export const PRIVATE = 'private';
export const PUBLIC = 'public';
export const ROLE_DEPT_BANKING = 'ROLE_DEPT_BANKING';
export const ROLE_DEPT_RESEARCH = 'ROLE_DEPT_RESEARCH';
export const ROLE_DEPT_CA = 'ROLE_DEPT_CA';
export const ROLE_DEPT_ECM = 'ROLE_DEPT_ECM';

export const ROLE_DEPT_LEGAL = 'ROLE_DEPT_LEGAL';
export const ROLE_DEPT_COMPLIANCE = 'ROLE_DEPT_COMPLIANCE';

export const VISIBILITIES = [PRIVATE, PUBLIC, ROLE_DEPT_BANKING, ROLE_DEPT_RESEARCH, ROLE_DEPT_CA, ROLE_DEPT_ECM, ROLE_DEPT_LEGAL, ROLE_DEPT_COMPLIANCE];

export const PRIVATE_LABEL = "Private";
export const PUBLIC_LABEL = "Public";
export const ROLE_DEPT_BANKING_LABEL = "Banking";
export const ROLE_DEPT_RESEARCH_LABEL = "Research";
export const ROLE_DEPT_CA_LABEL = "Corporate Access";
export const ROLE_DEPT_ECM_LABEL = "ECM";
export const ROLE_DEPT_LEGAL_LABEL = 'Legal';
export const ROLE_DEPT_COMPLIANCE_LABEL = 'Compliance';

export const VISIBILITY_OPTIONS = [
    { value: PRIVATE, label: PRIVATE_LABEL },
    { value: PUBLIC, label: PUBLIC_LABEL },
    { value: ROLE_DEPT_BANKING, label: ROLE_DEPT_BANKING_LABEL },
    { value: ROLE_DEPT_RESEARCH, label: ROLE_DEPT_RESEARCH_LABEL },
    { value: ROLE_DEPT_CA, label: ROLE_DEPT_CA_LABEL },
    { value: ROLE_DEPT_ECM, label: ROLE_DEPT_ECM_LABEL },
    { value: ROLE_DEPT_LEGAL, label: ROLE_DEPT_LEGAL_LABEL },
    { value: ROLE_DEPT_COMPLIANCE, label: ROLE_DEPT_COMPLIANCE_LABEL },
]

export const VISIBILITY_LABELS = {
    private: PRIVATE_LABEL,
    public: PUBLIC_LABEL,
    ROLE_DEPT_BANKING: ROLE_DEPT_BANKING_LABEL,
    ROLE_DEPT_RESEARCH: ROLE_DEPT_RESEARCH_LABEL,
    ROLE_DEPT_CA: ROLE_DEPT_CA_LABEL,
    ROLE_DEPT_ECM: ROLE_DEPT_ECM_LABEL,
    ROLE_DEPT_LEGAL: ROLE_DEPT_LEGAL_LABEL,
    ROLE_DEPT_COMPLIANCE: ROLE_DEPT_COMPLIANCE_LABEL,
}
