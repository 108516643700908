export default {
    data() {
        return {
            conflictCheckTimeout: null,
            conflicts: [],
        }
    },
    computed: {
        conflictCheckTrigger() {
            const contactIds = this.selectedContacts.map(c => c.id);
            return this.start + this.end + String(this.interactionType?.VID) + this.timezone + JSON.stringify(contactIds);
        }
    },
    watch: {
        conflictCheckTrigger() {
            clearTimeout(this.conflictCheckTimeout);
            this.conflictCheckTimeout = setTimeout(this.loadConflicts, 1500);
        }
    },
    methods: {
        loadConflicts() {
            if (this.start && this.end && this.timezone && this.event && this.selectedContacts.length > 0) {
                this.$axios.post('/api/interactions/check_conflicts', {
                    eventId: this.event ? this.event.id : null,
                    start: this.start,
                    end: this.end,
                    timezone: this.timezone,
                    participantIds: this.selectedContacts.map(c => c.id),
                    interactionIdsToIgnore: (this.interactionWorkingCopy && this.interactionWorkingCopy.id > 0) ? [this.interactionWorkingCopy.id] : [],
                })
                    .then((response) => {
                        this.conflicts = response.data;
                    });
            }
        }
    }
}
