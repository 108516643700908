export const STATUS_APPROVED = 1;
export const STATUS_PENDING = 2;
export const STATUS_REJECTED = 3;
export const STATUS_STRONG_CANDIDATE = 4;

const LABEL_APPROVED = 'Approved';
const LABEL_PENDING = 'Pending';
const LABEL_REJECTED = 'Rejected';
const LABEL_STRONG_CANDIDATE = 'Strong Candidate';

export const LABEL_BY_STATUS = {
    [STATUS_APPROVED]: LABEL_APPROVED,
    [STATUS_PENDING]: LABEL_PENDING,
    [STATUS_REJECTED]: LABEL_REJECTED,
    [STATUS_STRONG_CANDIDATE]: LABEL_STRONG_CANDIDATE,
};
