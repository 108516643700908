export default {
    methods: {
        addContactsToList(contactListId, contactIds, contactHash = null) {
            if(!contactIds && !contactHash) {
                alert("No contacts provided");
                return;
            }

            return this.$axios.post(`/api/contact_lists/add_contacts`, {
                contactListId,
                contactIds,
                hash: contactHash
            }).then((response) => {
                const result = response.data;

                let message;
                let type = 'success';
                if (result.added === 0) {
                    message = "All contacts are already on group"
                    type = "warning"
                } else if (result.skipped > 0) {
                    message = `${result.added} contacts were added, ${result.skipped} were already in the group`;
                } else {
                    message = "All contacts were added to the group";
                }

                const toastId = this.generateUUID();
                this.addToast({
                    type,
                    title: "Contacts adding to Group",
                    message,
                    id: toastId,
                });

                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
            })
        },

        removeContactsFromList(contactListId, contactIds, contactHash = null) {
            return this.$axios.post(`/api/contact_lists/remove_contacts`, {
                contactListId,
                contactIds,
                hash: contactHash
            }).then((response) => {
                const result = response.data;

                let message;
                let type = 'success';
                if (result.removed === 0) {
                    message = "None of the contacts was in the group"
                    type = "warning"
                } else if (result.skipped > 0) {
                    message = `${result.removed} contacts were removed, ${result.skipped} were not in the group`;
                } else {
                    message = "All contacts were removed from the group";
                }

                const toastId = this.generateUUID();
                this.addToast({
                    type,
                    title: "Contacts removing from Group",
                    message,
                    id: toastId,
                });

                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
            })
        }
    }
}
