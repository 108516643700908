import pluralize from 'pluralize';

export default {
    methods: {
        /**
         * Checks all properties of an object, if there is an attribute with a child object
         * with an ID, generate an IRI for it.
         *
         * @param obj Object which children should get converted to IRIs
         * @param specialElseOperation
         * @param properties
         *
         * @returns {any}
         */
        prepareIri(obj, specialElseOperation = null, properties = null) {

            if (typeof obj !== 'object' || obj === null) {
                return obj;
            }

            const entity = JSON.parse(JSON.stringify(obj));

            // If no properties to handle are defined, get a list of all properties
            if (!properties) {
                // TODO: This is quite dangerous. Attribute name of parent object (key) is not always the class name of the child object
                // E.g. event has attribute leadDepartment but lead department is of class contact department -> /api/lead_depatment does not work
                properties = Object.keys(entity).map(key => {
                    const snake = this.camelCaseToSnakeCase(key);
                    const split = snake.split("_");
                    const last = split.pop();
                    split.push(pluralize(last));
                    return {key, iri: split.join('_')}
                });
            }

            // Walk over all properties and replace IDs by IRIs
            properties.forEach((prop) => {
                const key = prop.key;
                const iri = prop.iri;
                if (obj[key] && obj[key]['@id']) {
                    this.$set(entity, key, obj[key]['@id'])
                } else if (entity[key] && obj[key].id) {
                    this.$set(entity, key, `/api/${iri}/${obj[key].id}`)
                } else if (typeof specialElseOperation === 'function') {
                    this.$set(entity, key, specialElseOperation(entity, key))
                }
            })

            return entity;

        },
        camelCaseToSnakeCase(str) {
            return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
        }
    }
}
