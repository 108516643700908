import moment from "moment/moment";

export default {
    computed: {
        isEventRoadshow() {
            if (this.currentEvent && this.currentEvent.eventType) {
                if (typeof this.currentEvent.eventType === 'object') {
                    return this.currentEvent.eventType.isRoadshow;
                } else {
                    const currentEventType = this.eventTypes.find(eventType => eventType['@id'] === this.currentEvent.eventType);
                    if (currentEventType) {
                        return currentEventType.isRoadshow;
                    }
                }
            }
            return false;
        },
        isAllowedToSelectMultipleCompanies() {
            return !this.isEventRoadshow;
        },
        meetingInteractionType() {
            return this.interactionTypes.find(interactionType => interactionType.VID === 'MEETING');
        },
        virtualMeetingInteractionType() {
            return this.interactionTypes.find(interactionType => interactionType.VID === 'VIRTUAL_MEETING');
        },
        interactionTypeForInteractions() {
            if (this.currentEvent && this.currentEvent.eventFormat && this.currentEvent.eventFormat === 'Virtual') {
                return this.virtualMeetingInteractionType;
            }

            // Default is meeting
            return this.meetingInteractionType;
        },
        currentEvent() {
            if (this.eventWorkingCopy) {
                return this.eventWorkingCopy;
            }

            return this.event;
        }
    },
    methods: {
        toInteractionParticipants(selectedContacts) {
            return selectedContacts.map((contact) => {
                const newInteractionParticipant = structuredClone(this.templates.interactionParticipant)
                newInteractionParticipant.contact = contact['@id'];
                return newInteractionParticipant;
            });
        },
        prepareInteractionsOfEvent(event, interactions, filterExistingParticipants) {
            if (interactions) {
                for (let i = 0; i < interactions.length; i++) {
                    interactions[i] = this.prepareInteractionOfEvent(event, interactions[i], filterExistingParticipants);
                }
            }
            return interactions
        },
        prepareInteractionOfEvent(event, interaction, filterExistingParticipants) {
            const preparedInteraction = this.prepareIri(interaction);
            const interactionParticipants = this.toInteractionParticipants(preparedInteraction.selectedContacts);

            if (filterExistingParticipants) {
                preparedInteraction.interactionParticipants = interactionParticipants.filter(
                    interactionParticipant => {
                        return !interaction.interactionParticipants.find(
                            existingInteractionParticipant => interactionParticipant.contact === existingInteractionParticipant['@id']
                        );
                    }
                );
            } else {
                preparedInteraction.interactionParticipants = interactionParticipants;
            }

            // Subject is now set in Backend, InteractionDataPersister

            preparedInteraction.notes = this.prepareIri(preparedInteraction.notes);

            // Move datetimes from "local time" to UTC
            preparedInteraction.start = moment.tz(preparedInteraction.start, preparedInteraction.timezone).utc().toISOString();
            preparedInteraction.end = moment.tz(preparedInteraction.end, preparedInteraction.timezone).utc().toISOString();

            delete preparedInteraction.selectedContacts;

            return preparedInteraction;
        },
        getDescriptionByEventGoalType(eventGoalType) {
            let title = null;

            if (typeof eventGoalType === 'object') {
                title = eventGoalType?.title;
            } else if (typeof eventGoalType === 'string') {
                if (this.eventGoalTypes && Array.isArray(this.eventGoalTypes) && this.eventGoalTypes.length > 0) {
                    title = this.eventGoalTypes.find(type => type['@id'] === eventGoalType)?.title
                }
            } else if (typeof eventGoalType === 'number') {
                if (this.eventGoalTypes && Array.isArray(this.eventGoalTypes) && this.eventGoalTypes.length > 0) {
                    title = this.eventGoalTypes.find(type => type.id === eventGoalType)?.title
                }
            }

            switch (title) {
                case 'Companies':
                    return 'Counts all event companies.';
                case 'Meetings':
                    return 'Counts all non-cancelled meetings at the event.';
                case 'Investors':
                    return 'Counts all investors participating at the event.';
                case 'Industry Professionals':
                    return 'Counts all industry professionals (investors and others) participating at the event.';
                case 'Meetings per company':
                    return 'Calculates the number of meetings per company at the event.';
                default:
                    return null;
            }
        },

        doSendConfirmationEmail(contactId, theEventIds) {
            this.$axios.post(`/api/events/prepare_confirmation_email/${contactId}`, {
                eventIds: theEventIds
            })
                .then(response => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        message: "Confirmation email has been prepared.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });

                    // this.submitted();
                    this.$emit("submitted");

                    this.$router.push('/emails/' + response.data.id);
                })
                .catch((e) => {
                    console.error(e);
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "danger",
                        title: "Error",
                        message: "Confirmation email could not be created.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                });
        }
    },
}
