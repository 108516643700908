import {mapActions, mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters({
            companyCache: "app/companyCache"
        }),
    },
    methods: {
        ...mapActions({
            addToCompanyCache: "app/addToCompanyCache"
        }),
        getCompanyById(companyId) {
            return new Promise((resolve, reject) => {
                const cacheMatches = this.companyCache.filter(company => Number.parseInt(company.id) === Number.parseInt(companyId));
                if (cacheMatches.length > 0) {
                    resolve(cacheMatches[0]);
                } else {
                    this.$axios.get(`/api/companies/${companyId}?groups[]=company:basics`).then(response => {
                        this.addToCompanyCache(response.data);
                        resolve(response.data);
                    })
                        .catch(error => {
                            console.error(error);
                            reject(error);
                        });
                }
            });
        },
    }
}
