export default {
    data() {
        return {
            countryUrl: "/api/countries",
            countryOptions: [],
        }
    },
    methods: {
        loadCountryOptions() {
            this.$axios.get(this.countryUrl)
                .then((response) => {
                    this.countryOptions = response.data;
                });
        },
    }
}
