export const TOP_SHAREHOLDER = 'TOP_SHAREHOLDER';
export const PAST_MEETING = 'PAST_MEETING';
export const PAST_PEER_MEETING = 'PAST_PEER_MEETING';
export const PAST_THIRD_PARTY_MEETING = 'PAST_THIRD_PARTY_MEETING';
export const PAST_THIRD_PARTY_PEER_MEETING = 'PAST_THIRD_PARTY_PEER_MEETING';
export const CANCELED_MEETING = 'CANCELED_MEETING';
export const INTEREST_LIST = "INTEREST_LIST";
export const PEERHOLDER = "PEERHOLDER"
export const READERSHIP_REPORT = "READERSHIP_REPORT";
export const CALL_LOG = "CALL_LOG";
export const INVESTMENT_PREFERENCE = "INVESTMENT_PREFERENCE";
export const PEERHOLDER_CALL_LOG = "PEERHOLDER_CALL_LOG";
export const SESSIONS_MEETING_REQUEST = "SESSIONS_MEETING_REQUEST";
export const SINGLE_COMPANY_EVENT_PARTICIPATION = "SINGLE_COMPANY_EVENT_PARTICIPATION";

export const INVESTOR_TARGET_REASON = [
    {
        label: "Top Shareholder",
        value: TOP_SHAREHOLDER,
        default: false,
    },
    {
        label: "Past Meeting",
        value: PAST_MEETING,
        default: true
    },
    {
        label: "Past Third Party Meeting",
        value: PAST_THIRD_PARTY_MEETING,
        default: true
    },
    {
        label: "Past Meeting with Third Party Peer",
        value: PAST_THIRD_PARTY_PEER_MEETING,
        default: true
    },
    {
        label: "Past Third Party Meeting with Peer",
        value: PAST_THIRD_PARTY_PEER_MEETING,
        default: true
    },
    {
        label: "Past Meeting with Peer",
        value: PAST_PEER_MEETING,
        default: true
    },
    {
        label: "Canceled Meeting",
        value: CANCELED_MEETING,
        default: true
    },
    {
        label: "Interest List",
        value: INTEREST_LIST,
        default: true
    },
    {
        label: "Peerholder",
        value: PEERHOLDER,
        default: false
    },
    {
        label: "Call Log",
        value: CALL_LOG,
        default: true
    },
    {
        label: "Investment Preferences",
        value: INVESTMENT_PREFERENCE,
        default: false
    },
    {
        label: "Peerholder's Call Log",
        value: PEERHOLDER_CALL_LOG,
        default: false
    },
    {
        label: "Readership Report",
        value: READERSHIP_REPORT,
        default: false
    },
    {
        label: "Sessions Meeting Requests",
        value: SESSIONS_MEETING_REQUEST,
        default: true
    },
    {
        label: "Single Company Event Participation",
        value: SINGLE_COMPANY_EVENT_PARTICIPATION,
        default: true
    }
];
