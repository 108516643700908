export const TIER_1 = 10;
export const TIER_2 = 20;
export const TIER_3 = 30;
export const TIER_5 = 50;

export const TIERS = [TIER_1, TIER_2, TIER_3, TIER_5];

export const TIER_1_LABEL = "Tier 1";
export const TIER_2_LABEL = "Tier 2";
export const TIER_3_LABEL = "Tier 3";
export const TIER_5_LABEL = "Tier 5";

export const TIER_OPTIONS = [
    { value: TIER_1, label: TIER_1_LABEL },
    { value: TIER_2, label: TIER_2_LABEL },
    { value: TIER_3, label: TIER_3_LABEL },
    { value: TIER_5, label: TIER_5_LABEL },
]

export const TIER_LABELS = {
    10: TIER_1_LABEL,
    20: TIER_2_LABEL,
    30: TIER_3_LABEL,
    50: TIER_5_LABEL,
}
