export default {
    data() {
        return {
            positiveCompanyCharacteristics: [],
            negativeCompanyCharacteristics: [],
        }
    },
    watch: {
        'contact.investmentPreferences': {
            handler() {
                this.positiveCompanyCharacteristics = [];
                this.negativeCompanyCharacteristics = [];
                for (let i = 0; i < this.contact.investmentPreferences.length; i++) {
                    if (this.contact.investmentPreferences[i].isPositive) {
                        this.positiveCompanyCharacteristics.push(this.contact.investmentPreferences[i].companyCharacteristic);
                    } else {
                        this.negativeCompanyCharacteristics.push(this.contact.investmentPreferences[i].companyCharacteristic);
                    }
                }
            },
            deep: true,
            immediate: true,
        }
    },
    methods: {
        updateInvestmentPreferencesForContact(contactId, positiveCompanyCharacteristicIds, negativeCompanyCharacteristicIds) {
            return new Promise((resolve, reject) => {
                this.$axios.patch(`/api/contacts/investment_preferences/${contactId}/update`,
                    {
                        positiveCompanyCharacteristicIds,
                        negativeCompanyCharacteristicIds
                    }, {
                        headers: {
                            'Content-Type': 'application/merge-patch+json'
                        }
                    })
                    .then((response) => {
                        resolve(response);
                    }).catch((err) => {
                    reject(err);
                });
            });
        }

    },
}
