export const OPTION_COMPANY = 'COMPANY';
export const OPTION_FUND = 'FUND';
export const OPTION_OTHER = 'OTHER';

export const CLASSIFICATION_OPTIONS = [
    {
        label: "Company",
        value: OPTION_COMPANY,
    },
    {
        label: "Fund",
        value: OPTION_FUND,
    },
    {
        label: "Other",
        value: OPTION_OTHER,
    }
];
