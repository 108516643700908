export const OPTION_INTERESTED = 'INTERESTED';
export const OPTION_NOT_INTERESTED = 'NOT INTERESTED';

export const INTEREST_LIST_STATUS = [
    {
        label: "Interested",
        value: OPTION_INTERESTED,
    },
    {
        label: "Not Interested",
        value: OPTION_NOT_INTERESTED,
    },

];
