export default {
    methods: {
        saveQueryAndExport(query, exportPath) {
            return this.$axios.post('/api/export/save_query', query).then(response => {

                let exportUrl = `${this.$axios.defaults.baseURL}${exportPath}`;
                exportUrl += exportUrl.includes('?') ? '&' : '?';
                exportUrl += `${this.buildQueryString({save: response.data.save})}`;
                window.open(exportUrl, '_blank').focus();
            });
        },
        downloadFile(url, name) {
            const link = document.createElement('a');

            link.style.display = 'none';
            link.href = url;
            link.download = name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        downloadBlob(blob, name) {
            const objectUrl = URL.createObjectURL(blob);
            this.downloadFile(objectUrl, name);
            URL.revokeObjectURL(objectUrl);
        },
    }
}
